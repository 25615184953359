.ProgressBar {
    width: calc(100% - 55px);
    height: 8px;
    background-color: #cce5fe;
    border-radius: 5px;
  }
  
  .Progress {
    background-color: #003A70;
    height: 100%;
    margin: 0;
    border-radius: 5px;
  }