.Dropzone {
    height: 140px;
    width: 190px;
    background-color: #fff;
    border: 2px dashed #cccccc;
    border-radius: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 15px;
    font-weight: 600;
    color: #8A8A8A;
}

.Highlight {
    background-color: #f5eef7;
}

.Icon {
    opacity: 0.5;
    height: 40px;
    width: 40px;
    // filter to make black svg to #8A8A8A color
    filter: invert(53%) sepia(0%) saturate(1461%) hue-rotate(164deg) brightness(102%) contrast(95%);
}

.FileInput {
    display: none;
}