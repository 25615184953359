.Upload {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
}

.Content {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    box-sizing: border-box;
    width: 100%;
}

.Files {
    margin-left: 32px;
    align-items: flex-start;
    justify-items: flex-start;
    flex: 1;
    overflow-y: auto;
}

.Actions {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 5px;
    padding-bottom: 5px;
}

.Filename {
    font-size: 16px;
    font-weight: 600;
    color: #8A8A8A;
}

.Row {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 48px;
    padding: 8px;
    overflow: hidden;
    box-sizing: border-box;
}

.SuccessIcon {
    margin-left: 32px;
    filter: invert(30%) sepia(80%) saturate(2351%) hue-rotate(104deg) brightness(95%) contrast(103%);
}

.FailIcon {
    margin-left: 32px;
    filter: invert(11%) sepia(87%) saturate(4295%) hue-rotate(356deg) brightness(83%) contrast(114%);
}

.ProgressWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

button {
    margin-top: 5px;
    padding: 5px 16px;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
}

button:disabled {
     display: none;
}