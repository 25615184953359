body {
    font-family: Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
}

.ms-Button--default {
    background-color: #fff;
    color: #003a70;
    border: solid 2px #003a70;
    border-radius: 15px;
    -webkit-box-shadow: 2px 2px 4px #bac0c8;
    -moz-box-shadow: 2px 2px 4px #bac0c8;
    box-shadow: 2px 2px 4px #bac0c8;
}
.ms-Button--default:hover {
    background-color: #cce5fe;
    color: #003a70;
    border: solid 2px #003a70;
}
.ms-Button--primary {
    background-color: #33618d;
    color: #fff;
    border: solid 2px #13325f;
    border-radius: 15px;
    -webkit-box-shadow: 2px 2px 4px #bac0c8;
    -moz-box-shadow: 2px 2px 4px #bac0c8;
    box-shadow: 2px 2px 4px #bac0c8;
}
.ms-Button--primary:hover {
    color: #fff;
    background-color: #003a70;
    border: solid 2px #13325f;
}
.renderLinefeed {
    white-space: pre-line;
}
button.clearButton {
    background-color: #c1c1c1;
    color: #5f5f5f;
    border: solid 2px #8c8c8c;
    border-radius: 15px;
    -webkit-box-shadow: 2px 2px 4px #bac0c8;
    -moz-box-shadow: 2px 2px 4px #bac0c8;
    box-shadow: 2px 2px 4px #bac0c8;
}
button.clearButton:hover {
    color: #5f5f5f;
    background-color: #d5d5d5;
    border: solid 2px #8c8c8c;
}

button.floatHelpButton {
    margin: 0px;
    top: auto;
    right: 180px;
    bottom: 80px;
    left: auto;
    position: fixed;
}

.App {
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;

    .ms-Spinner-circle {
        width: 40px;
        height: 40px;
        border-color: #003a70 #b3daff #b3daff;
    }
    .ms-Spinner-label {
        font-size: larger;
        color: #003a70;
    }

    .ms-ComboBox {
        border: solid 2px #cccccc;
        border-radius: 7px;
        .ms-Button {
            margin-top: 0;
        }
    }
    .ms-ComboBox::after {
        border: 0;
    }

    .ms-Dropdown .ms-Dropdown-title {
        border-radius: 7px;
        border: solid 2px #cccccc;
    }

    .ms-TextField {
        margin-bottom: 10px;
    }
    .ms-TextField:last-child {
        margin-left: auto;
        margin-right: 0;
    }
    .ms-ChoiceFieldGroup {
        margin-top: 10px;
    }
    .ms-TextField-fieldGroup {
        border: solid 2px #cccccc;
        border-radius: 7px;
    }
    .ms-TextField-description {
        font-size: 0.875rem;
        color: #8a8a8a;
    }
    input.ms-ChoiceField-input{
        height: fit-content;
        width: fit-content;
    }
    .ms-TooltipHost{
        i {
            font-size: 18px;
            font-weight: 600;
            color: #8a8a8a;
        }
    }
    .App-header {
        height: 100px;
        padding: 10px 172px;
        background-color: #fff;
        border-bottom: 1px solid #707070;
        display: flex;
        justify-content: left;
        align-items: center;
        img {
            max-height: 42px;
        }
    }

    .App-navigation {
        margin-top: 20px;
        background-color: #fff;
        h1 {
            height: 60px;
            display: flex;
            justify-content: center;
            margin-top: 0;
            font-size: 1.625rem;
            font-weight: 700;
            color: #003a70;
        }
        .App-textInfo {
            margin-left: 15px;
            margin-right: 15px;
            h1 {
                text-align: center;
                font-size: 1.3rem;
                font-weight: 600;
                color: #003a70;
            }
            h2 {
                margin-top: 50px;
                font-size: 1.125rem;
                font-weight: 500;
                color: #003a70;
                ul{
                    margin: 8px 0 0 0;
                }
                li {
                    font-size: 1rem;
                    margin-bottom: 8px;
                }
            }
        }
        label {
            margin-right: 25px;
            font-weight: 600;
        }
        .ms-Link {
            font-weight: 600;
            color: #7ca2d0;
            text-decoration: none;
        }
        .ms-Link:hover {
            background-color: transparent;
        }
        span.current {
            color: #003a70;
            font-weight: 600;
            margin-left: 15px;
        }
    }

    .App-dashboard {
        padding: 10px 0 25px 0;
        background-color: #fff;
        form {
            max-width: 1080px;
            margin: 0 auto;
            color: #171717;
            section {
                border: solid 2px #dfe3e8;
                border-radius: 10px;
                padding: 30px 20px 0px 20px;
                margin-bottom: 9px;
                height: 46px;
            }
            h1 {
                margin-left: 5px;
                font-size: 1.625rem;
                font-weight: 700;
                color: #003a70;
            }
        }
        span.link {
            cursor: pointer;
            color: #1681ea;
            text-decoration: underline;
        }
        span.action {
            cursor: pointer;
            color: #363536;
            text-decoration: underline;
        }
    }

    .App-formbody {
        padding: 10px 0 25px 0;
        background-color: #fff;
        form {
            background-color: #ebf3fd;
            border: solid 2px #c7d7eb;
            border-radius: 15px;
            max-width: 840px;
            margin: 0 auto;
            color: #171717;
            label {
                margin-bottom: 4px;
                color: #171717;
            }
            h1 {
                font-size: x-large;
            }
            h2 {
                font-size: medium;
                margin: 0;
            }
            h3 {
                font-size: medium;
                font-weight: 400;
                margin: 5px 0 15px 0;
                color: #8a8a8a;
            }
            section {
                border-bottom: solid 2px #dfe3e8;
                padding: 20px 20px 25px 20px;
            }
            section:last-child {
                border-bottom: none;
            }
            section.applicationIDSection {
                height: 59px;
                display: grid;
                grid-template-columns: auto 180px;
                .col1 {
                    align-self: center;
                    font-size: 14px;
                }
                .col2 {
                    justify-self: right;
                    img {
                        max-height: 60px;
                        max-width: 180px;
                        border: solid 2px #dfe3e8;
                        border-radius: 10px;
                    }
                }
            }
            .requiredItem:after {
                color: #a4262c;
                font-style: italic;
                content: ' (required)';
                font-weight: 600;
            }
            .requiredField {
                label::after {
                    color: #a4262c;
                    font-style: italic;
                    content: ' (required)';
                    font-weight: 600;
                }
            }
            .requiredFieldCustom {
                color: #a4262c;
                font-style: italic;
                font-weight: 600;
            }
            .italicFieldCustom {
                color: #8a8a8a;
                font-style: italic;
                font-weight: 600;
            }
            label.requiredField:after {
                color: #a4262c;
                font-style: italic;
                content: ' (required)';
                font-weight: 600;
            }
            div.groupName {
                margin-top: 20px;
                font-size: 14px;
                font-weight: 600;
            }
            span.studyLocationCheckBoxContainer {
                display: inline-flex;
                align-items: baseline;
                img {
                    max-height: 14px;
                    margin: 3px;
                }
            }
        }
    }

    .App-footer {
        margin-top: 30px;
        margin-bottom: 10px;
        height: 150px;
        background-color: #fff;
        border-top: 1px solid #707070;
        padding-top: 30px;
        display: flex;
        justify-content: center;
        img {
            padding: 10px;
            height: 70px;
        }
    }
}
